import React, { useEffect, useState } from "react";
import useLocale from "./useLocale";

const useLocalePrefix = () => {
  const locale = useLocale();
  const [prefix, setPrefix] = useState("");

  useEffect(() => {
    setPrefix(locale == "fr" ? "/fr" : "");
  }, [locale]);
  return prefix;
};

export default useLocalePrefix;
